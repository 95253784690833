/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import '../../VodafoneIdea.scss';
import VodafoneImg from '../../../../../../assets/images/vodafone.jpg';
import IdeaImg from '../../../../../../assets/images/idealogo.jpg';
import CloudImg from '../../../../../../assets/images/cloud.jpg';
import lotImg from '../../../../../../assets/images/lot.jpg';
import WireImg from '../../../../../../assets/images/wire.jpg';
import WirelessImg from '../../../../../../assets/images/wireless.jpg';
import './IplcCafForm.css';
import { JsonToTable } from 'react-json-to-table';
import {
  useExcelJsonList,
  useLoadRomsFormFieldListData,
  useLoadFieldValues,
  useLoadCompanySeal,
} from '../../../../../../hooks/form.hooks';
import { loadImageBlob } from '../../../../../../utils/aws.util';
import { useAuthContext } from '../../../../../../context/auth.context';
import { encode } from '../../cafCrf.util';
import { Spinner, Center, useTheme } from '@chakra-ui/react';

const IplcCafForm = ({ formData, queueData, romsActivity, submittedForms }) => {
  const [cafData, setCafData] = useState(false);
  const [iplcCafDataUpdated, setUpdatedCafData] = useState(null);
  const [companySeal, setCompanySeal] = useState(null);
  const [authorizedSignatoryName, setAuthSignName] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [imageType, setImageType] = useState(null);
  const [acctMngSignImageData, setacctMngSignImageData] = useState(null);
  const [acctMngSignImageType, setacctMngSignImageType] = useState(null);
  const [excelJson, setexcelJson] = useState(null);

  const { mutate } = useLoadRomsFormFieldListData();
  const { mutate: loadOrderFormValues } = useLoadFieldValues();
  const { mutate: loadExcelJsonValues } = useExcelJsonList();
  const { mutate: loadCompanySeal } = useLoadCompanySeal();
  let componentRef = React.useRef();
  const chakraTheme = useTheme();

  const {
    state: { authData },
  } = useAuthContext();

  const fetchFieldListData = () => {
    mutate(
      {
        fieldList: {
          organization_id: authData.organization_id,
          account_id: authData.account_id,
          workforce_id: authData.workforce_id,
          form_id: formData.form_id,
        },
        fieldValues: {
          form_id: formData.form_id,
          form_transaction_id: formData.form_transaction_id
            ? formData.form_transaction_id
            : formData.data_form_transaction_id,
          start_from: 0,
          limit_value: 50,
        },
      },
      {
        onSuccess: async data => {
          let finalFields = data;
          setCafData(false);
          setUpdatedCafData(finalFields);
        },
        onError: async err => {
          console.log('error occurred while loading fields', err);
        },
      }
    );
  };

  const fetchOrderFormValues = () => {
    let orderForm = submittedForms.find(
      item => item.form_id === romsActivity.order_form_id
    );
    loadOrderFormValues(
      {
        form_id: romsActivity?.order_form_id,
        form_transaction_id: !!orderForm
          ? orderForm.data_form_transaction_id
          : 0,
      },
      {
        onSuccess: async res => {
          if (res.length > 0) {
            let ExcelIndex = res[0].activity_inline_data.findIndex(
              res1 => res1.field_id === 11029
            );
            let excelUrl;
            if (ExcelIndex > -1) {
              excelUrl = res[0].activity_inline_data[ExcelIndex].field_value;
            }
            if (!!excelUrl) {
              loadExcelJsonValues(
                { bucket_url: excelUrl },
                {
                  onSuccess: async result => {
                    setexcelJson(JSON.parse(result));
                  },
                }
              );
            }
          }
        },
      }
    );
  };

  const fetchCompanySeal = () => {
    loadCompanySeal(
      {
        form_id: 1289,
        field_id: 0,
        activity_id: queueData.activity_id,
      },
      {
        onSuccess: async res => {
          let companySeal = '';
          let authorizedSignatoryName = '';
          let data = [];
          if (res.length > 0) {
            data = JSON.parse(res[0].data_entity_inline).form_submitted;
          } else {
            data = [];
          }
          data.forEach(function (fieldObj, index) {
            if (fieldObj.field_id === 11106) {
              companySeal = fieldObj.field_value;
            }
            if (fieldObj.field_id === 11105) {
              authorizedSignatoryName = fieldObj.field_value;
            }
          });
          setCompanySeal(companySeal);
          setAuthSignName(authorizedSignatoryName);
        },
      }
    );
  };

  useEffect(() => {
    setCafData(true);
    fetchCompanySeal();
    fetchFieldListData();
    fetchOrderFormValues();
  }, []);

  useEffect(() => {
    let data = iplcCafDataUpdated;
    if (!!data) {
      if (!!companySeal) {
        // customer company seal
        let custCompanySeal = companySeal;
        loadImageBlob(custCompanySeal, (data, type) => {
          setImageData(data);
          setImageType(type);
        });
      }
      if (!!data[12079].value) {
        // manager sign url
        let acctManagerSignUrl = data[12079].value;
        loadImageBlob(acctManagerSignUrl, (data, type) => {
          setacctMngSignImageData(data);
          setacctMngSignImageType(type);
        });
      }
    }
  }, [iplcCafDataUpdated, companySeal, authorizedSignatoryName]);

  let IplcCafContentView = '';
  if (!!iplcCafDataUpdated) {
    let firstCaps = '';
    let secondBold = '';
    let Signcss;

    if (!!authorizedSignatoryName) {
      let customerSignSplit = authorizedSignatoryName.split(' ');
      if (customerSignSplit.length > 1) {
        firstCaps = customerSignSplit['0'].slice(0, 1).toUpperCase();
        secondBold = customerSignSplit['1'];
        Signcss = 'signate_img input_sign';
      } else {
        firstCaps = customerSignSplit['0'];
        secondBold = '';
        Signcss = 'signate_img input_sign under_line';
      }
    }

    IplcCafContentView = (
      <div>
        <div
          style={{ textAlign: 'left' }}
          className="tab_content"
          ref={el => (componentRef = el)}
        >
          <page size="A4">
            <div className="section1">
              <div className="section1_top">
                <div className="section1_top_col">
                  <p className="pad_l_r10 over_hid">
                    <img src={VodafoneImg} />
                    <img src={IdeaImg} className="pullr" />
                  </p>
                  <p className="customhed">Customer Application Form</p>
                  <div className="empty1"></div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <p className="vodabar">
                    Vodafone Idea Business Services
                    <br />
                    International Private Leased Circuits/
                    <br />
                    International Ethernet Private Line
                  </p>
                </div>
                <div className="signate float_img">
                  {firstCaps !== '' ? (
                    <div style={{ padding: '10px' }} className={Signcss}>
                      {firstCaps + '  .  '}
                      <span className="under_line">{secondBold}</span>
                    </div>
                  ) : (
                    ''
                  )}
                  {imageData !== null && imageData !== '' ? (
                    <div style={{ padding: '10px' }} className="signate_img">
                      <img
                        src={
                          imageData !== null
                            ? 'data:' +
                              imageType +
                              ';base64,' +
                              encode(imageData)
                            : ''
                        }
                        alt={'Company Seal'}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </page>
          <page size="A4">
            <div className="section2">
              <div className="font8">
                <p className="over_hid">
                  <img src={VodafoneImg} />
                  <img src={IdeaImg} className="pullr" />
                </p>
                <p className="customfom">
                  {' '}
                  Customer Application Form - IPLC/Bandwidth <br />
                  Connect/International Ethernet Private Line{' '}
                </p>
                <div className="w_85">
                  <div className="over_hid">
                    <div className="pd_t5">
                      <strong>Vodafone Idea Business Servies</strong>{' '}
                      <span className="pad_l10">
                        Kindly fill the form in BLOCK letters
                      </span>
                      <div className="caf">
                        CAF ID
                        <input
                          className="form_col"
                          type="text"
                          name=""
                          value={iplcCafDataUpdated[11883].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="over_hid mar_t-5">
                    <div className="pd_t5">
                      <strong>Company(Customer) Name</strong>{' '}
                      <span className="pad_l10">
                        <input
                          className="form_col_input input_red"
                          type="text"
                          name=""
                          value={iplcCafDataUpdated[11884].value}
                          readOnly
                        />
                      </span>
                      <div className="caf_inner">
                        <strong>Account Code</strong>
                        <input
                          className="form_col no_top"
                          type="text"
                          name=""
                          value={iplcCafDataUpdated[11885].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <p className="sign">
                    <strong>Authorised Signatory Details</strong>
                  </p>
                </div>
                <div className="over_hid auth">
                  <div className="fleft width45">
                    Name
                    <input
                      className="form_col width85"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11887].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width25">
                    Contact No:
                    <input
                      className="form_col width59"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11888].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width30">
                    Email:
                    <input
                      className="form_col width80"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11889].value}
                      readOnly
                    />
                  </div>
                </div>
                <p className="font5">
                  I/We hereby order for VIL IPLC Service subject to terms and
                  conditions as described in the service order form. We furnish
                  the necessary details as follow.
                </p>
                <p className="ligthbg">HEADQUARTERS ADDRESS </p>
                <p className="auth">
                  Contact Person name
                  <input
                    className="form_col width60"
                    type="text"
                    name=""
                    value={iplcCafDataUpdated[11892].value}
                    readOnly
                  />
                </p>
                <div className="auth margin0 over_hid">
                  <div className="fleft width50">
                    Designation
                    <input
                      className="form_col width80"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11893].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width50">
                    Dept. Name
                    <input
                      className="form_col width80"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11894].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="auth margin0">
                  <span className="width54px">Address</span>
                  <input
                    className="form_col width90"
                    type="text"
                    name=""
                    value={iplcCafDataUpdated[11895].value}
                    readOnly
                  />
                </div>
                <div className="auth margin0">
                  <span className="width54px">Country</span>
                  <input
                    className="form_col width90"
                    type="text"
                    name=""
                    value={iplcCafDataUpdated[11896].value}
                    readOnly
                  />
                </div>
                <div className="auth margin0 over_hid">
                  <div className="fleft width50">
                    City/Village/Post office
                    <input
                      className="form_col width65"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11897].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width25">
                    Pincode
                    <input
                      className="form_col width65"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11898].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width25">
                    Telephone
                    <input
                      className="form_col width65"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11899].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="auth margin0 over_hid">
                  <div className="fleft width58">
                    Landmark
                    <input
                      className="form_col width84"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11900].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width42">
                    <span className="faxn">Fax No.</span>
                    <input
                      className="form_col width66"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11901].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="auth margin0 over_hid">
                  <div className="fleft width58">
                    Contact Email Id
                    <input
                      className="form_col width77"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11902].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width42">
                    Mobile No / Alt No.
                    <input
                      className="form_col width66"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11903].value}
                      readOnly
                    />
                  </div>
                </div>
                <p className="ligthbg">BILLING ADDRESS </p>
                <p className="auth">
                  Contact Person name
                  <input
                    className="form_col width60"
                    type="text"
                    name=""
                    value={iplcCafDataUpdated[11905].value}
                    readOnly
                  />
                </p>
                <div className="auth margin0 over_hid">
                  <div className="fleft width50">
                    Designation
                    <input
                      className="form_col width80"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11906].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width50">
                    Dept. Name
                    <input
                      className="form_col width80"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11907].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="auth margin0">
                  <span className="width54px">Address</span>
                  <input
                    className="form_col width90"
                    type="text"
                    name=""
                    value={iplcCafDataUpdated[11908].value}
                    readOnly
                  />
                </div>
                <div className="auth margin0">
                  <span className="width54px"> Country</span>
                  <input
                    className="form_col width90"
                    type="text"
                    name=""
                    value={iplcCafDataUpdated[11909].value}
                    readOnly
                  />
                </div>
                <div className="auth margin0 over_hid">
                  <div className="fleft width50">
                    City/Village/Post office
                    <input
                      className="form_col width65"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11910].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width25">
                    Pincode
                    <input
                      className="form_col width65"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11911].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width25">
                    Telephone
                    <input
                      className="form_col width65"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11912].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="auth margin0 over_hid">
                  <div className="fleft width58">
                    Landmark
                    <input
                      className="form_col width84"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11913].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width42">
                    <span className="faxn">Fax No.</span>
                    <input
                      className="form_col width66"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11914].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="auth margin0 over_hid">
                  <div className="fleft width58">
                    Contact Email Id
                    <input
                      className="form_col width77"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11915].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width42">
                    Mobile No / Alt No.
                    <input
                      className="form_col width66"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11916].value}
                      readOnly
                    />
                  </div>
                </div>
                <p className="ligthbg">CUSTOMER INFORMATION</p>
                <div className="check over_hid custype">
                  <div className="fleft width15_2 pad_7t">Customer Type</div>
                  <div className="fleft width14">
                    {iplcCafDataUpdated[11918].value === 'General' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    General
                  </div>
                  <div className="fleft width14">
                    {iplcCafDataUpdated[11918].value === 'ISP' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    ISP
                  </div>
                  <div className="fleft width14">
                    {iplcCafDataUpdated[11918].value === 'OSP' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    OSP
                  </div>
                  <div className="fleft width14">
                    {iplcCafDataUpdated[11918].value === 'Telemarketer' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Telemarketer
                  </div>
                  <div className="fleft width14">
                    {iplcCafDataUpdated[11918].value === 'ВРО' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    BPO
                  </div>
                  <div className="fleft width14">
                    {iplcCafDataUpdated[11918].value === 'Others' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Others
                  </div>
                </div>
                <div className="check over_hid custype">
                  <div className="fleft width15_2 pad_7t">
                    Customer Vertical Type
                  </div>
                  <div className="fleft width14">
                    {iplcCafDataUpdated[11919].value === 'Automotive' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Automotive
                  </div>
                  <div className="fleft width14">
                    {iplcCafDataUpdated[11919].value === 'Consumer Goods' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Consumer Goods
                  </div>
                  <div className="fleft width14">
                    {iplcCafDataUpdated[11919].value ===
                    'Diversified & Projects' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Diversified & Projects
                  </div>
                  <div className="fleft width14">
                    {iplcCafDataUpdated[11919].value === 'Education' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Education
                  </div>
                  <div className="fleft width14">
                    {iplcCafDataUpdated[11919].value === 'Engineering' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Engineering
                  </div>
                  <div className="fleft width14">
                    {iplcCafDataUpdated[11919].value === 'Finance' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Finance
                  </div>
                </div>
                <div className="check over_hid custype">
                  <div className="fleft width15_2 pad_7t"> </div>
                  <div className="fleft width14">
                    {iplcCafDataUpdated[11919].value === 'Government' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Government
                  </div>
                  <div className="fleft width14">
                    {iplcCafDataUpdated[11919].value === 'Health Care' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Health Care
                  </div>
                  <div className="fleft width14 fnt8">
                    {iplcCafDataUpdated[11919].value ===
                    'Industry Associations' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Industry Associations
                  </div>
                  <div className="fleft width14">
                    {iplcCafDataUpdated[11919].value === 'IT & Telecom' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    IT & Telecome
                  </div>
                  <div className="fleft width14">
                    {iplcCafDataUpdated[11919].value === 'Logistics' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Logistics
                  </div>
                  <div className="fleft width14 fnt6">
                    {iplcCafDataUpdated[11919].value ===
                    'Manufacturing & Processing' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Manufacturing & Processing
                  </div>
                </div>
                <div className="check over_hid custype">
                  <div className="fleft width15_2 pad_7t"> </div>
                  <div className="fleft width14">
                    {iplcCafDataUpdated[11919].value === 'Trading' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Trading
                  </div>
                  <div className="fleft width14">
                    {iplcCafDataUpdated[11919].value === 'Services' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Services
                  </div>
                  <div className="fleft width14">
                    {iplcCafDataUpdated[11919].value === 'Projects EPC' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Projects EPC
                  </div>
                  <div className="fleft width14">
                    {iplcCafDataUpdated[11919].value === 'Others' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Others
                  </div>
                </div>
                <p className="ligthbg">INSTALLATION ADDRESS - A</p>
                <p className="margin0">This Address is for installation</p>
                <p className="auth">
                  Contact Person name
                  <input
                    className="form_col width83"
                    type="text"
                    name=""
                    value={iplcCafDataUpdated[11922].value}
                    readOnly
                  />
                </p>
                <div className="auth margin0 over_hid">
                  <div className="fleft width50">
                    Designation
                    <input
                      className="form_col width80"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11923].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width50">
                    Dept. Name
                    <input
                      className="form_col width80"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11924].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="auth margin0">
                  <span className="width54px">Address</span>
                  <input
                    className="form_col width90"
                    type="text"
                    name=""
                    value={iplcCafDataUpdated[11925].value}
                    readOnly
                  />
                </div>
                <div className="auth pad_b5">
                  <span className="width54px">Country </span>
                  <input
                    className="form_col width90"
                    type="text"
                    name=""
                    value={iplcCafDataUpdated[11926].value}
                    readOnly
                  />
                </div>
                <div className="auth margin0 over_hid">
                  <div className="fleft width50">
                    City/Village/Post office
                    <input
                      className="form_col width65"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11927].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width25">
                    Pincode
                    <input
                      className="form_col width65"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11928].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width25">
                    Telephone
                    <input
                      className="form_col width65"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11929].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="auth margin0 over_hid">
                  <div className="fleft width58">
                    Landmark
                    <input
                      className="form_col width84"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11930].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width42">
                    <span className="faxn">Fax No.</span>
                    <input
                      className="form_col width66"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11931].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="auth margin0 over_hid">
                  <div className="fleft width58">
                    Contact Email Id
                    <input
                      className="form_col width77"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11932].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width42">
                    Mobile No / Alt No.
                    <input
                      className="form_col width66"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11933].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check margin0 over_hid">
                  <div className="fleft width15">
                    GSTIN
                    {iplcCafDataUpdated[11934].value === 'GSTIN' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                  </div>
                  <div className="fleft width15">
                    UIN
                    {iplcCafDataUpdated[11934].value === 'UIN' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                  </div>
                  <div className="fleft width15">
                    GST_ISD
                    {iplcCafDataUpdated[11934].value === 'GST_ISD' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                  </div>
                </div>
                <div className="check margin0 over_hid typecheck">
                  <div className="fleft width12 pad_t2">GST Customer Type</div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11935].value === 'Normal' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Normal
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11935].value === 'SEZ' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    SEZ
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11935].value === 'Diplomat' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Diplomat
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11935].value === 'Embassy' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Embassy
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11935].value === 'Consulate' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Consulate
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11935].value === 'Special Agency' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Special Agency
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11935].value ===
                    'Person/Class of Persons' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Person/Class of Persons
                  </div>
                </div>
                <div className="signate float_img">
                  {firstCaps !== '' ? (
                    <div style={{ padding: '10px' }} className={Signcss}>
                      {firstCaps + '  .  '}
                      <span className="under_line">{secondBold}</span>
                    </div>
                  ) : (
                    ''
                  )}
                  {imageData !== null && imageData !== '' ? (
                    <div style={{ padding: '10px' }} className="signate_img">
                      <img
                        src={
                          imageData !== null
                            ? 'data:' +
                              imageType +
                              ';base64,' +
                              encode(imageData)
                            : ''
                        }
                        alt={'Company Seal'}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <p className="auth">
                  GSTIN / UIN / GST_ISD No
                  <input
                    className="form_col wid50"
                    type="text"
                    name=""
                    value={iplcCafDataUpdated[11936].value}
                    readOnly
                  />
                </p>
                <div className="auth">
                  <span className="width70px in_block">GST Reg. Address</span>
                  <input
                    className="form_col width78"
                    type="text"
                    name=""
                    value={iplcCafDataUpdated[11937].value}
                    readOnly
                  />
                </div>
                <div className="auth">
                  <span className="width70px in_block">
                    in installation state
                  </span>
                  <input
                    className="form_col width78"
                    type="text"
                    name=""
                    value=""
                  />
                </div>
                <div className="check over_hid typecheck gstcheck pad_t10">
                  <div className="fleft width12 pad_t2">GST Customer Dept</div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11938].value === 'IT' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    IT
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11938].value === 'Tech' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Tech
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11938].value === 'Cust Service' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Cust Service
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11938].value === 'Legal' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Legal
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11938].value === 'Finance' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Finance
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11938].value === 'Purchase' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Purchase
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11938].value === 'SCM' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    SCM
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11938].value === 'Delivery' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Delivery
                  </div>
                  <div className="fleft width20 pad_t2">
                    Others
                    <input
                      className="form_col oth_gst"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11939].value}
                      readOnly
                    />
                  </div>
                </div>
                <p className="auth namsupp">
                  Name of Supporting Document
                  <input
                    className="form_col wid50"
                    type="text"
                    name=""
                    value={iplcCafDataUpdated[11940].value}
                    readOnly
                  />
                </p>
                <p className="gsttext">
                  (GST Certificate, SEZ Certificate, Diplomat Certificate)
                </p>
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
          </page>
          <page size="A4">
            <div className="section2">
              <div className="font8">
                <p className="customfom">
                  {' '}
                  Customer Application Form - IPLC/Bandwidth <br />
                  Connect/International Ethernet Private Line{' '}
                </p>
                <p className="ligthbg">INSTALLATION ADDRESS - B</p>
                <p className="margin0">This Address is for installation</p>
                <p className="auth">
                  Contact Person name
                  <input
                    className="form_col width83"
                    type="text"
                    name=""
                    value={iplcCafDataUpdated[11943].value}
                    readOnly
                  />
                </p>
                <div className="auth margin0 over_hid">
                  <div className="fleft width50">
                    Designation
                    <input
                      className="form_col width80"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11944].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width50">
                    Dept. Name
                    <input
                      className="form_col width80"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11945].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="auth margin0">
                  <span className="width54px">Address</span>
                  <input
                    className="form_col width90"
                    type="text"
                    name=""
                    value={iplcCafDataUpdated[11946].value}
                    readOnly
                  />
                </div>
                <div className="auth pad_b5">
                  <span className="width54px">Country</span>
                  <input
                    className="form_col width90"
                    type="text"
                    name=""
                    value={iplcCafDataUpdated[11947].value}
                    readOnly
                  />
                </div>
                <div className="auth margin0 over_hid">
                  <div className="fleft width50">
                    City/Village/Post office
                    <input
                      className="form_col width65"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11948].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width25">
                    Pincode
                    <input
                      className="form_col width65"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11949].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width25">
                    Telephone
                    <input
                      className="form_col width65"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11950].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="auth margin0 over_hid">
                  <div className="fleft width58">
                    Landmark
                    <input
                      className="form_col width84"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11951].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width42">
                    <span className="faxn">Fax No.</span>
                    <input
                      className="form_col width66"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11952].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="auth margin0 over_hid">
                  <div className="fleft width58">
                    Contact Email Id
                    <input
                      className="form_col width77"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11953].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width42">
                    Mobile No / Alt No.
                    <input
                      className="form_col width66"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11954].value}
                      readOnly
                    />
                  </div>
                </div>
                <p className="ligthbg">ORDER DETAILS</p>
                <div className="check over_hid mpls">
                  <div className="fleft width70px pad_7t">
                    <strong>Type of Order</strong>
                  </div>
                  <div className="fleft width14">
                    {iplcCafDataUpdated[11956].value === 'New' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    New
                  </div>
                </div>
                <div className="check over_hid mpls">
                  <div className="fleft width70px pad_7t">Service Ordered</div>
                  <div className="fleft width20">
                    {iplcCafDataUpdated[11957].value === 'Bandwidth Connect' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Bandwidth Connect
                  </div>
                  <div className="fleft width14">
                    {iplcCafDataUpdated[11957].value === 'IEPL' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    IEPL
                  </div>
                  <div className="fleft width14">
                    {iplcCafDataUpdated[11957].value === 'IPLC' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    IPLC
                  </div>
                </div>
                <div className="over_hid mplstextin padb10">
                  <div className="fleft width50">
                    <div className="fleft width90px padt5 pad_l_r10">
                      {' '}
                      Feasibility ID (FR ID) A End
                    </div>
                    <div className="fleft width60">
                      <input
                        className="form_col width90"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[11958].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="fleft width50">
                    <div className="fleft width90px padt5 pad_l_r10">
                      {' '}
                      Feasibility ID (FR ID) B End{' '}
                    </div>
                    <div className="fleft width60">
                      <input
                        className="form_col width90"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[11959].value}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="check over_hid">
                  <div className="fleft width70px pad_7t">
                    {' '}
                    Contract Period(in Months){' '}
                  </div>
                  <div className="fleft width16">
                    <input
                      className="form_col width90 input_red"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11960].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid">
                  <div className="fleft width70px pad_7t">
                    {' '}
                    Billing Periodicity{' '}
                  </div>
                  <div className="fleft width16">
                    {iplcCafDataUpdated[11961].value === 'Monthly' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Monthly{' '}
                  </div>
                  <div className="fleft width16">
                    {iplcCafDataUpdated[11961].value === 'Bi-Monthly' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Bi-Monthly{' '}
                  </div>
                  <div className="fleft width16">
                    {iplcCafDataUpdated[11961].value === 'Quarterly' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Quarterly
                  </div>
                  <div className="fleft width16">
                    {iplcCafDataUpdated[11961].value === 'Half-Yearly' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Half-Yearly{' '}
                  </div>
                  <div className="fleft width16">
                    {iplcCafDataUpdated[11961].value === 'Annually' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Annually{' '}
                  </div>
                </div>
                <div className="check over_hid">
                  <div className="fleft width70px pad_7t"> Billing In </div>
                  <div className="fleft width16">
                    {iplcCafDataUpdated[11962].value === 'Advance' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Advance{' '}
                  </div>
                  <div className="fleft width16">
                    {iplcCafDataUpdated[11962].value === 'Arrears' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Arrears{' '}
                  </div>
                </div>
                <div className="check margin0 over_hid">
                  <div className="fleft width70px pad_7t">
                    {' '}
                    CPE Provided By{' '}
                  </div>
                  <div className="fleft width16">
                    {iplcCafDataUpdated[11963].value === 'Vodafone Idea' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Vodafone Idea
                  </div>
                  <div className="fleft width16">
                    {iplcCafDataUpdated[11963].value === 'Customer' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Customer{' '}
                  </div>
                  <div className="fleft pad_7t">
                    {' '}
                    If Provided by Vodafone Idea{' '}
                  </div>
                  <div className="fleft width16 pad_l10">
                    {iplcCafDataUpdated[11964].value === 'Rent' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Rent{' '}
                  </div>
                  <div className="fleft width16">
                    {iplcCafDataUpdated[11964].value === 'Sale' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Sale{' '}
                  </div>
                  <div className="fleft width16">
                    {iplcCafDataUpdated[11964].value === 'None' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    None{' '}
                  </div>
                </div>
                <p className="gsttext margin0">CPE Details</p>
                <div className="domestictable">
                  <table width="70%" border="0" cellSpacing="0" cellPadding="0">
                    <tbody>
                      <tr>
                        <td>Sr. No.</td>
                        <td>Type (Standard/Other)</td>
                        <td>Make</td>
                        <td>Model</td>
                        <td>Version</td>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td>{iplcCafDataUpdated[11966].value}</td>
                        <td>{iplcCafDataUpdated[11967].value}</td>
                        <td>{iplcCafDataUpdated[11968].value}</td>
                        <td>{iplcCafDataUpdated[11969].value}</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="check over_hid typecheck gstcheck pad_7t">
                  <div className="fleft width15 pad_t2">Circuit Type</div>
                  <div className="fleft width15">
                    {iplcCafDataUpdated[11970].value === 'Protected' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Protected
                  </div>
                  <div className="fleft width30">
                    {iplcCafDataUpdated[11970].value === 'Unprotected' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Unprotected
                  </div>
                </div>
                <div className="signate float_img">
                  {firstCaps !== '' ? (
                    <div style={{ padding: '10px' }} className={Signcss}>
                      {firstCaps + '  .  '}
                      <span className="under_line">{secondBold}</span>
                    </div>
                  ) : (
                    ''
                  )}
                  {imageData !== null && imageData !== '' ? (
                    <div style={{ padding: '10px' }} className="signate_img">
                      <img
                        src={
                          imageData !== null
                            ? 'data:' +
                              imageType +
                              ';base64,' +
                              encode(imageData)
                            : ''
                        }
                        alt={'Company Seal'}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className="check over_hid typecheck gstcheck pad_5t">
                  <div className="fleft width25 pad_5t"> Bandwidth </div>
                  <div className="fleft width20">
                    <input
                      className="form_col width90 input_red"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11971].value}
                      readOnly
                    />
                    Mbps
                  </div>
                  <div className="fleft width30 pad_5t pad_l10"> Distance </div>
                  <div className="fleft width22">
                    <input
                      className="form_col width90"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11972].value}
                      readOnly
                    />
                    Kms
                  </div>
                </div>
                <div className="check over_hid pad_t6">
                  <div className="fleft width20 pad_t2">
                    If Channelized Bandwidth,{' '}
                  </div>
                  <div className="fleft width15 padt5 pad_l10">
                    {' '}
                    No. of Channels{' '}
                  </div>
                  <div className="fleft width15">
                    <input
                      className="form_col width90"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11974].value}
                      readOnly
                    />
                  </div>
                </div>
                <p className="margin0">
                  <strong>Link Details (A End)</strong>
                </p>
                <div className="check over_hid typecheck gstcheck pad_t10">
                  <div className="fleft npwidth17">Last Mile Details</div>
                  <div className="fleft width12"> Media</div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11976].value === 'Copper' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Copper{' '}
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11976].value === 'Fiber' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Fiber
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11976].value === 'Wireless' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Wireless
                  </div>
                </div>
                <div className="check over_hid typecheck gstcheck pad_5t">
                  <div className="fleft npwidth17">Primary LM Technology </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11977].value === 'TDM' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    TDM
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11977].value === 'CEN' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    CEN{' '}
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11977].value === 'EOSDH' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    EoSDH
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11977].value === 'BWA' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    BWA
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11977].value === 'Radio P2P' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    RadioP2P
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11977].value === 'ISDN' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    ISDN
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11977].value === '3G' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    3G
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11977].value === 'GPRS' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    GPRS
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11977].value === 'VSAT' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    VSAT
                  </div>
                  <div className="fleft npwidth17 pad_t2">
                    {iplcCafDataUpdated[11977].value === 'Others' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Others
                    <input
                      className="form_col oth_gst"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11978].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid typecheck gstcheck pad_5t">
                  <div className="fleft npwidth17">Backup LM </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11979].value === 'TDM' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    TDM
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11979].value === 'CEN' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    CEN{' '}
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11979].value === 'EOSDH' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    EoSDH
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11979].value === 'BWA' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    BWA
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11979].value === 'Radio P2P' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    RadioP2P
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11979].value === 'ISDN' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    ISDN
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11979].value === '3G' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    3G
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11979].value === 'GPRS' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    GPRS
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11979].value === 'VSAT' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    VSAT
                  </div>
                  <div className="fleft npwidth17 pad_t2">
                    {iplcCafDataUpdated[11979].value === 'Nil' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Nill{' '}
                  </div>
                </div>
                <div className="check over_hid typecheck gstcheck pad_5t">
                  <div className="fleft npwidth17">
                    Customer end equipment*{' '}
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11980].value === 'Router/Switch' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Router/Switch
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11980].value === 'Modem' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Modem
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11980].value === 'Convertor' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Convertor
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11980].value === 'Others' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Others
                  </div>
                  <div className="fleft width15">
                    If Router/Switch - Qty
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11981].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width14">
                    If Modem - Qty
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11982].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width14">
                    If Convertor - Qty
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11983].value}
                      readOnly
                    />
                  </div>
                </div>
                <p className="margin0">
                  # Detailed BOM/BOQ to be attached separately
                </p>
                <div className="check over_hid typecheck gstcheck pad_t10">
                  <div className="fleft npwidth17">Customer end Interface </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11985].value === 'V.35' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    V.35
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11985].value === 'G.703' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    G.703{' '}
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11985].value ===
                    'Ethernet Electrical' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Ethernet Electrical{' '}
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11985].value === 'BNC' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    BNC
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11985].value === 'Ethernet Optical' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Ethernet Optical
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11985].value === 'Optical' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Optical
                  </div>
                  <div className="fleft npwidth20 pad_t2">
                    {iplcCafDataUpdated[11985].value === 'Other' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Other
                    <input
                      className="form_col oth_gst"
                      type="text"
                      name=""
                      value=""
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid typecheck gstcheck pad_5t">
                  <div className="fleft npwidth17"> Dual Homing</div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11986].value === 'Yes' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Yes
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11986].value === 'No' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    No{' '}
                  </div>
                  <div className="fleft width12 pad_t3"> If yes, then </div>
                  <div className="fleft width15 pad_5t">
                    {' '}
                    Service Provider PoP1{' '}
                  </div>
                  <div className="fleft width10">
                    <input
                      className="form_col width90"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11987].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width25 pad_5t pad_l10">
                    {' '}
                    Service Provider PoP2, if dual homing{' '}
                  </div>
                  <div className="fleft width10">
                    <input
                      className="form_col width90"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11988].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid typecheck gstcheck pad_5t">
                  <div className="fleft width25 pad_5t">
                    {' '}
                    Primary Last Mile Service Provider{' '}
                  </div>
                  <div className="fleft width20">
                    <input
                      className="form_col width90"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11989].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width30 pad_5t pad_l10">
                    {' '}
                    Secoundary Last Mile Service Provider{' '}
                  </div>
                  <div className="fleft width22">
                    <input
                      className="form_col width90"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11990].value}
                      readOnly
                    />
                  </div>
                </div>
                <p className="margin0">
                  <strong>Link Details (B End)</strong>
                </p>
                <div className="check over_hid typecheck gstcheck pad_t10">
                  <div className="fleft npwidth17">Last Mile Details</div>
                  <div className="fleft width12"> Media</div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11992].value === 'Copper' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Copper{' '}
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11992].value === 'Fiber' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Fiber
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11992].value === 'Wireless' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Wireless
                  </div>
                </div>
                <div className="check over_hid typecheck gstcheck pad_5t">
                  <div className="fleft npwidth17">Primary LM Technology </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11993].value === 'TDM' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    TDM
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11993].value === 'CEN' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    CEN{' '}
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11993].value === 'EOSDH' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    EoSDH
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11993].value === 'BWA' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    BWA
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11993].value === 'Radio P2P' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    RadioP2P
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11993].value === 'ISDN' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    ISDN
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11993].value === '3G' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    3G
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11993].value === 'GPRS' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    GPRS
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11993].value === 'VSAT' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    VSAT
                  </div>
                  <div className="fleft npwidth17 pad_t2">
                    {iplcCafDataUpdated[11993].value === 'Others' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Others
                    <input
                      className="form_col oth_gst"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11994].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid typecheck gstcheck pad_5t">
                  <div className="fleft npwidth17">Backup LM </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11995].value === 'TDM' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    TDM
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11995].value === 'CEN' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    CEN{' '}
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11995].value === 'EOSDH' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    EOSDH
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11995].value === 'BWA' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    BWA
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11995].value === 'Radio P2P' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    RadioP2P
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11995].value === 'ISDN' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    ISDN
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11995].value === '3G' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    3G
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11995].value === 'GPRS' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    GPRS
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11995].value === 'VSAT' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    VSAT
                  </div>
                  <div className="fleft npwidth17 pad_t2">
                    {iplcCafDataUpdated[11995].value === 'Nil' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Nill{' '}
                  </div>
                </div>
                <div className="check over_hid typecheck gstcheck pad_5t">
                  <div className="fleft npwidth17">
                    Customer end equipment*{' '}
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11996].value === 'Router/Switch' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Router/Switch
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11996].value === 'Modem' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Modem{' '}
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[11996].value === 'Convertor' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Convertor{' '}
                  </div>

                  <div className="fleft width12">
                    {iplcCafDataUpdated[11996].value === 'Others' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Others{' '}
                  </div>

                  <div className="fleft width15">
                    If Router/Switch - Qty
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11997].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width14">
                    If Modem - Qty
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11998].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width14">
                    If Convertor - Qty
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[11999].value}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          </page>
          <page size="A4">
            <div className="section2">
              <div className="font8">
                <p className="customfom">
                  {' '}
                  Customer Application Form - IPLC/Bandwidth <br />
                  Connect/International Ethernet Private Line{' '}
                </p>
                <p className="margin0">
                  # Detailed BOM/BOQ to be attached separately
                </p>
                <div className="check over_hid typecheck gstcheck pad_t10">
                  <div className="fleft npwidth17">Customer end Interface </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[12001].value === 'V.35' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    V.35
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[12001].value === 'G.703' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    G.703{' '}
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[12001].value ===
                    'Ethernet Electrical' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Ethernet Electrical{' '}
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[12001].value === 'BNC' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    BNC
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[12001].value === 'Ethernet Optical' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Ethernet Optical
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[12001].value === 'Optical' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Optical
                  </div>
                  <div className="fleft npwidth20 pad_t2">
                    {iplcCafDataUpdated[12001].value === 'Other' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Other
                    <input
                      className="form_col oth_gst"
                      type="text"
                      name=""
                      value=""
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid typecheck gstcheck pad_5t">
                  <div className="fleft npwidth17"> Dual Homing</div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[12002].value === 'Yes' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Yes
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[12002].value === 'No' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    No{' '}
                  </div>
                  <div className="fleft width12 pad_t3"> If yes, then </div>
                  <div className="fleft width15 pad_5t">
                    {' '}
                    Service Provider PoP1{' '}
                  </div>
                  <div className="fleft width10">
                    <input
                      className="form_col width90"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[12003].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width25 pad_5t pad_l10">
                    {' '}
                    Service Provider PoP2, if dual homing{' '}
                  </div>
                  <div className="fleft width10">
                    <input
                      className="form_col width90"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[12004].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid typecheck gstcheck pad_5t">
                  <div className="fleft width25 pad_5t">
                    {' '}
                    Primary Last Mile Service Provider{' '}
                  </div>
                  <div className="fleft width20">
                    <input
                      className="form_col width90"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[12005].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width30 pad_5t pad_l10">
                    {' '}
                    Secoundary Last Mile Service Provider{' '}
                  </div>
                  <div className="fleft width22">
                    <input
                      className="form_col width90"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[12006].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_5t">
                  <div className="fleft width170px pad_t3">
                    {' '}
                    Self Care Service Variant{' '}
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[12007].value === 'None' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    None
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[12007].value === 'Silver' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Silver
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[12007].value === 'Silver Plus' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Sliver Plus
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[12007].value === 'Gold' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Gold
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[12007].value === 'Gold Plus' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Gold Plus
                  </div>
                </div>
                <div className="check over_hid pad_t5">
                  <div className="signate float_img">
                    {firstCaps !== '' ? (
                      <div style={{ padding: '10px' }} className={Signcss}>
                        {firstCaps + '  .  '}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ''
                    )}
                    {imageData !== null && imageData !== '' ? (
                      <div style={{ padding: '10px' }} className="signate_img">
                        <img
                          src={
                            imageData !== null
                              ? 'data:' +
                                imageType +
                                ';base64,' +
                                encode(imageData)
                              : ''
                          }
                          alt={'Company Seal'}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="fleft width170px pad_t3">
                    {' '}
                    Managed Service Varients{' '}
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[12008].value === 'Standard' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Standard
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[12008].value === 'Silver' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Silver
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[12008].value === 'Gold' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Gold
                  </div>
                  <div className="fleft width12">
                    {iplcCafDataUpdated[12008].value === 'Platinum' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Platinum
                  </div>
                </div>
                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3">
                    {' '}
                    CPF Managed by{' '}
                  </div>
                  <div className="fleft width_12">
                    {iplcCafDataUpdated[12009].value === 'Vodafone Idea' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Vodafone Idea
                  </div>
                  <div className="fleft width_12">
                    {iplcCafDataUpdated[12009].value === 'Customer' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Customer
                  </div>
                </div>
                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3">
                    {' '}
                    CPE Maintained by{' '}
                  </div>
                  <div className="fleft width_12">
                    {iplcCafDataUpdated[12010].value === 'Vodafone Idea' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Vodafone Idea
                  </div>
                  <div className="fleft width_12">
                    {iplcCafDataUpdated[12010].value === 'Customer' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Customer
                  </div>
                </div>
                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3"> SLA Type </div>
                  <div className="fleft width_12">
                    {iplcCafDataUpdated[12011].value === 'Standard' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Silver
                  </div>
                  <div className="fleft width_12">
                    {iplcCafDataUpdated[12011].value === 'Customized' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Customized
                  </div>

                  <div className="fleft width_12">
                    {iplcCafDataUpdated[12011].value === 'None' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    None
                  </div>
                </div>
                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3">
                    {' '}
                    If SLA Type Customized
                  </div>
                  <div className="fleft width15">
                    Service Availability (%)
                    <br />
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[12013].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15">
                    MTTR (Hours)
                    <br />
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[12014].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15">
                    Latency (ms)
                    <br />
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[12015].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="pad_t5">
                  <p className="ligthbg">CHARGE DESCRIPTION</p>
                </div>
                <div className="char">
                  <div className="check over_hid pad_t5">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      <strong>Specify Currency</strong>
                    </div>
                    <div className="fleft width7">
                      {iplcCafDataUpdated[12017].value === 'INR' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      INR{' '}
                    </div>
                    <div className="fleft width7">
                      {iplcCafDataUpdated[12017].value === 'US$' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      US${' '}
                    </div>
                    <div className="fleft width7">
                      {iplcCafDataUpdated[12017].value === 'GBP' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      GBP{' '}
                    </div>
                    <div className="fleft width7">
                      {iplcCafDataUpdated[12017].value === 'EURO' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      EURO{' '}
                    </div>
                  </div>
                  <div className="check over_hid pad_t5">
                    <div className="fleft width170px pad_t3"> &nbsp; </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width7"> &nbsp; </div>
                    <div className="fleft width15 textcenter">
                      {' '}
                      One Time (A){' '}
                    </div>
                    <div className="fleft width15 textcenter">
                      {' '}
                      Annual Recurring (B){' '}
                    </div>
                    <div className="fleft width15 textcenter">
                      {' '}
                      Security Deposit (C){' '}
                    </div>
                    <div className="fleft width15 textcenter">
                      {' '}
                      Grand Total (A+B+C){' '}
                    </div>
                  </div>
                  <div className="check over_hid">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      Service Rental
                    </div>
                    <div className="fleft width7">&nbsp; </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12018].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12019].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12020].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12021].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t5">
                    <div className="fleft width170px pad_t3"> SLA Charges</div>
                    <div className="fleft width7">&nbsp; </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12022].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12023].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter"> &nbsp; </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12024].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t5">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      Self Care Portal Service Charges
                    </div>
                    <div className="fleft width7">&nbsp; </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12025].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12026].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter"> &nbsp; </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12027].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t5">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      Managed Service Charges
                    </div>
                    <div className="fleft width7">&nbsp; </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12028].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12029].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter"> &nbsp; </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12030].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t5">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      Managed CPE Charges
                    </div>
                    <div className="fleft width7">&nbsp; </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12031].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12032].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter"> &nbsp; </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12033].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t5">
                    <div className="fleft width170px pad_t3"> CPE Rentals</div>
                    <div className="fleft width7">&nbsp; </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12034].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12035].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12036].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12037].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t5">
                    <div className="fleft width170px pad_t3"> </div>
                    <div className="fleft width7 pad_t5">CPE 1 </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12038].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12039].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12040].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12041].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t5">
                    <div className="fleft width170px pad_t3"> </div>
                    <div className="fleft width7 pad_t5">CPE 2 </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12042].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12043].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12044].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12045].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t5">
                    <div className="fleft width170px pad_t3"> </div>
                    <div className="fleft width7 pad_t5">CPE 3 </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12046].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12047].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12048].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12049].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t5">
                    <div className="fleft width170px pad_t3"> </div>
                    <div className="fleft width7 pad_t5">CPE 4 </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12050].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12051].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12052].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12053].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t5">
                    <div className="fleft width170px pad_t3"> </div>
                    <div className="fleft width7 pad_t5">CPE 5 </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12054].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12055].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12056].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12057].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t5">
                    <div className="fleft width170px pad_t3">
                      Miscellaneous Charges - 1{' '}
                    </div>
                    <div className="fleft width7 pad_t5"> &nbsp; </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12058].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12059].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12060].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12061].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t5">
                    <div className="fleft width170px pad_t3">
                      Miscellaneous Charges - 2{' '}
                    </div>
                    <div className="fleft width7 pad_t5"> &nbsp; </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12062].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12063].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12065].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter"> &nbsp; </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12064].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t5">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      Total Amount Payable{' '}
                    </div>
                    <div className="fleft width7 pad_t5"> &nbsp; </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75 input_red"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12066].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75 input_red"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12067].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter"> &nbsp; </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75 input_red"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12068].value}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3">
                    <strong> Total Order Value</strong>{' '}
                  </div>
                  <div className="fleft width7 pad_t5"> &nbsp; </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width45 mar_l7">
                    <input
                      className="form_col width90"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[12069].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="pad_t5">
                  <p className="ligthbg">COMMENTS</p>
                </div>
                <p>
                  <textarea
                    className="textarea"
                    placeholder="Comments/Special Consideration:"
                    value={iplcCafDataUpdated[12070].value}
                  ></textarea>
                </p>
              </div>
            </div>
          </page>
          <page size="A4">
            <div className="section2">
              <div className="font8">
                <p className="customfom">
                  {' '}
                  Customer Application Form - IPLC/Bandwidth <br />
                  Connect/International Ethernet Private Line{' '}
                </p>
                <div className="over_hid">
                  <div className="fleft width65 pad_t10">
                    <p className="marb5">
                      Applicant's Name* / Authorised Signatory's Name**{' '}
                    </p>
                    <p className="margin0">
                      <input
                        className="form_col widthfull inputext"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12072].value}
                        readOnly
                      />
                    </p>
                    <div className="over_hid pad_t30">
                      <div className="fleft width40">
                        Designation**
                        <input
                          className="form_col width55 inputext"
                          type="text"
                          name=""
                          value={iplcCafDataUpdated[12073].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width30">
                        Place
                        <input
                          className="form_col width90 inputext design"
                          type="text"
                          name=""
                          value={iplcCafDataUpdated[12074].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width30">
                        Date
                        <input
                          className="form_col width90 inputext design"
                          type="text"
                          name=""
                          value={iplcCafDataUpdated[12075].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flright width30">
                    <div style={{ padding: '5px' }} className="signate">
                      {firstCaps !== '' ? (
                        <div className={Signcss}>
                          {firstCaps + '  .  '}
                          <span className="under_line">{secondBold}</span>
                        </div>
                      ) : (
                        ''
                      )}
                      <div>
                        Signature of the Customer / Authorised signatory with in
                        this box only (In case of institutinal, please affix
                        office/company seal){' '}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="over_hid">
                  <div className="fleft width65 pad_t10">
                    <p className="marb5">Account Manager Name</p>
                    <p className="margin0">
                      <input
                        className="form_col widthfull inputext"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12077].value}
                        readOnly
                      />
                    </p>
                    <p className="marb5">Account Manager Circle Office </p>
                    <p className="margin0">
                      <input
                        className="form_col width50 inputext_brd_list"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12078].value}
                        readOnly
                      />
                    </p>
                    <p className="marb5">Channel Partner Name</p>
                    <p className="margin0">
                      <input
                        className="form_col widthfull inputext"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12080].value}
                        readOnly
                      />
                    </p>
                    <p className="marb5">Channel Partner Code</p>
                    <div className="signate float_img">
                      {firstCaps !== '' ? (
                        <div style={{ padding: '10px' }} className={Signcss}>
                          {firstCaps + '  .  '}
                          <span className="under_line">{secondBold}</span>
                        </div>
                      ) : (
                        ''
                      )}
                      {imageData !== null && imageData !== '' ? (
                        <div
                          style={{ padding: '10px' }}
                          className="signate_img"
                        >
                          <img
                            src={
                              imageData !== null
                                ? 'data:' +
                                  imageType +
                                  ';base64,' +
                                  encode(imageData)
                                : ''
                            }
                            alt={'Company Seal'}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    <p className="margin0">
                      <input
                        className="form_col width50 inputext_brd_list"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12081].value}
                        readOnly
                      />
                    </p>
                    <br />
                    <br />
                    <p>
                      <strong>Vodafone Idea Limited</strong> ( Formerly Idea
                      Cellular Limited )<br />
                      An Adity Birla Group and Vodafone partneship
                      <br />
                      MergerCo CIN No: L32100GJ1996PLC030976
                      <br />
                      <strong>Registred Office:</strong> Suman Tower, Plot no.
                      18, Sector 11, Gandhinagar - 382 011, Gujrat, T: +91 79
                      66714000 | F: +91 79 23232251
                      <br />
                      <strong>Circle Office Address:</strong>{' '}
                    </p>
                  </div>
                  <div className="flright width30">
                    <div
                      style={{ padding: '5px', height: '120px' }}
                      className="signate"
                    >
                      {acctMngSignImageData !== null &&
                      acctMngSignImageData !== '' ? (
                        <img
                          height="90px"
                          width="100px"
                          src={
                            acctMngSignImageData !== null
                              ? 'data:' +
                                acctMngSignImageType +
                                ';base64,' +
                                encode(acctMngSignImageData)
                              : ''
                          }
                          alt={'Signature of Account Manager'}
                        />
                      ) : (
                        ''
                      )}
                      <div>Signature of the Account Manager </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <p className="ligthbg font8">
                    IPLC Service under ILD License Terms &amp; Conditions
                  </p>
                  <p className="font10">
                    <strong>
                      This "Customer Application Form" (CAF) shall mean together
                      (i) the customer information form; and (ii) the following
                      conditions ("Regulatory Terms"), which are required to be
                      provided/completed with effect from /prior to activation
                      of IPLC point to point Services provided by Vodafone Idea
                      Limited under its ILD License and these 'Regulatory Terms'
                      shall continue during the term Of service.{' '}
                    </strong>{' '}
                  </p>
                  <div className="regtext">
                    REGULATORY TERMS GOVERNING INTERNATIONAL PRIVATE LEASED
                    CIRCUITS SERVICES
                  </div>
                  <div className="over_hid">
                    <div className="fleft width49">
                      <ul className="list">
                        <li>
                          <span className="pad_r5">1.</span>
                          <span>
                            {' '}
                            The Customer undertakes to fully comply with all the
                            applicable laws and regulations in force from time
                            to time ( Including but not limited to Indian
                            telegraph Act 1885, Indian telegraph rules 1951 ,
                            Information Technology Act 2000 and amendments
                            thereof, TRA Act 1997 ) governing the use of Telecom
                            services provided under this CAF.{' '}
                          </span>
                        </li>
                        <li>
                          <span className="pad_r5">2.</span>
                          <span>
                            {' '}
                            The Customer shall strictly ensure the bonafide use
                            Of the leased circuits provided under this CAF.{' '}
                          </span>
                        </li>
                        <li>
                          <span className="pad_r5">3.</span>
                          <span>
                            {' '}
                            The Customer undertakes to provide the Certified
                            copy of their OSP Registration or ILD License or any
                            other Telecom License in case they are holding the
                            same or become holder of the same at any point of
                            time, while availing the services under this CAF.{' '}
                          </span>
                        </li>
                        <li>
                          <span className="pad_r5">4.</span>
                          <span>
                            {' '}
                            The Customer agrees and undertakes that the leased
                            circuits provided to him shall not be used for any
                            of the activities mentioned hereunder - <br></br>•
                            Voice Telephony (PSTN /ISDN/SIP Trunking/PLMN).{' '}
                            <br></br>• Connectivity of the leased circuits with
                            any PLMN/PSTN /Public Network <br></br>• Reselling
                            of the leased circuits (not applicable if Customer
                            holding ILD License / IJL-VNO with ILD
                            Authorization){' '}
                          </span>
                        </li>
                        <li>
                          <span className="pad_r5">5.</span>
                          <span>
                            {' '}
                            The Customer must ensure that Service provided, is
                            not used for any unlawful purposes. The Customer
                            shall ensure that objectionable, obscene,
                            unauthorized or any other content, messages or
                            communications infringing copyright, Intellectual
                            property right and international & domestic cyber
                            laws, in any form or inconsistent with the laws of
                            India, are not carried in their network by them or
                            any other authorized person using their network or
                            during the provisioning of Services. The Customer
                            must take all necessary measures to prevent such
                            use. The use of the Service for anti-national
                            activities shall be construed as an offence
                            punishable under the Indian Penal Code or other
                            applicable law...{' '}
                          </span>
                        </li>
                        <li>
                          <span className="pad_r5">6.</span>
                          <span>
                            {' '}
                            Customer shall allow VIL employees or its authorized
                            personnel, enter at all times into the premises
                            where the Services are provided for periodical
                            inspection, installing, maintaining, replacing and
                            removing equipment hardware and/or software prior
                            to, during and after the provision of the services.{' '}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="fright width49">
                      <ul className="list">
                        <li>
                          <span className="pad_r5">7.</span>
                          <span>
                            {' '}
                            VIL warrants that the Services shall be of the
                            acceptable grade, consistent with the established
                            and generally accepted standards. However, the
                            quality, functionality, availability or reliability
                            of the same may be affected from time to time.{' '}
                          </span>
                        </li>
                        <li>
                          <span className="pad_r5">8.</span>
                          <span>
                            {' '}
                            VIL shall be entitled to collect/recover financial
                            penalty, if any, from the Customer as may be levied
                            by Government on it on account of any breach of
                            regulatory terms in this CAF and shall also be
                            entitled, without any liability, to refuse, limit,
                            suspend, vary, disconnect and or interrupt the
                            Services, in whole or in part, at any time, for any
                            reason and/or due to various factors including but
                            not limited to: <br></br>• Government's rules,
                            regulations, orders, directions, etc. <br></br>•
                            Combat potential fraud, sabotage, etc. <br></br>•
                            Force Majeure circumstances.{' '}
                          </span>
                        </li>
                        <li>
                          <span className="pad_r5">9.</span>
                          <span>
                            {' '}
                            VIL may change, amend or revise the above regulatory
                            terms and conditions at any time as and when
                            necessary in order to comply with any statutory,
                            legal or regulatory requirements and the Customer
                            agrees to abide by such modified terms and
                            conditions. Such changes, amendments or revisions
                            shall be deemed effective upon posting an updated
                            and duly dated regulatory compliance to the Customer
                            via email, fax, post or through any other medium
                            opted by VIL{' '}
                          </span>
                        </li>
                        <li>
                          <span className="pad_r5">10.</span>
                          <span>
                            {' '}
                            Customer has read, understands and agrees to VIL
                            Privacy Policy which is available at
                            https://www.vodafone.in/privacy policy.{' '}
                          </span>
                        </li>
                        <li>
                          <span className="pad_r5">11.</span>
                          <span>
                            {' '}
                            The Regulatory Terms under this CAF binds the
                            Customer, their executors, administrators,
                            successors and permitted assignees to the terms &
                            conditions of the CAF, along with the Terms &
                            conditions of MSA & SLA ( if any){' '}
                          </span>
                        </li>
                        <li>
                          <span className="pad_r5">12.</span>
                          <span>
                            {' '}
                            GST & SEZ STATUS: The customer will be solely
                            responsible for providing the correct GST number and
                            'SEP' related information against the "Place of
                            Supply" as per the GST law. S/he waives off all the
                            rights to dispute or withhold any payments in case
                            of incorrect GST Numbers or incorrect SEZ
                            certificates/ status being provided, which don't
                            match the details available on the Government GSTN
                            portal. The customer acknowledges that s/he will not
                            be able to claim the GST tax credit under such
                            circumstances and shall pay 100% invoiced amount,
                            including taxes.{' '}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </page>
          <page size="A4">
            <div className="section2">
              <div className="font8">
                <p className="customfom">
                  {' '}
                  Customer Application Form - IPLC/Bandwidth <br />
                  Connect/International Ethernet Private Line{' '}
                </p>
                <div className="margin0">
                  <p className="ligthbg">Terms & Conditions</p>
                </div>
                <p className="margin0">
                  <strong>Customer Responsibility</strong>
                </p>
                <p className="margin0">
                  Customer will be required to provide the following for site
                  readlness
                </p>
                <div className="over_hid pad_t10">
                  <div className="fleft width45">
                    Lockable, conditioned space for housing telco equipment{' '}
                  </div>
                  <div className="fleft mar_l7">
                    <input
                      className="form_col width37px inputext_brd"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[12085].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="over_hid">
                  <div className="fleft width45 pad_t3">Power</div>
                  <div className="fleft mar_l7">
                    <input
                      className="form_col width37px inputext_brd"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[12086].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="over_hid">
                  <div className="fleft width45 pad_t3">Earthing</div>
                  <div className="fleft mar_l7">
                    <input
                      className="form_col width37px inputext_brd"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[12087].value}
                      readOnly
                    />
                  </div>
                </div>
                <p className="margin0">
                  Customerwill be required to grant the following permissions to
                  VIL & it's carrier partner
                </p>
                <div className="signate float_img">
                  {firstCaps !== '' ? (
                    <div style={{ padding: '10px' }} className={Signcss}>
                      {firstCaps + '  .  '}
                      <span className="under_line">{secondBold}</span>
                    </div>
                  ) : (
                    ''
                  )}
                  {imageData !== null && imageData !== '' ? (
                    <div style={{ padding: '10px' }} className="signate_img">
                      <img
                        src={
                          imageData !== null
                            ? 'data:' +
                              imageType +
                              ';base64,' +
                              encode(imageData)
                            : ''
                        }
                        alt={'Company Seal'}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className="over_hid pad_t10">
                  <div className="fleft width45 pad_t3">
                    Right of way permission
                  </div>
                  <div className="fleft mar_l7">
                    <input
                      className="form_col width37px inputext_brd"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[12089].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="over_hid">
                  <div className="fleft width45 pad_t3">
                    Roof top space for pole/tower installiton
                  </div>
                  <div className="fleft mar_l7">
                    <input
                      className="form_col width37px inputext_brd"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[12090].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="over_hid">
                  <div className="fleft width45 pad_t3">
                    Ground space for pole/tower installiton
                  </div>
                  <div className="fleft mar_l7">
                    <input
                      className="form_col width37px inputext_brd"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[12091].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="over_hid">
                  <div className="fleft width45 pad_t3">
                    Site access to employees, authorised representatives of
                    <br />
                    VIL/its affiliaties the execution of their duty for
                    <br />
                    the purpose of installation and/or equipment configuration
                  </div>
                  <div className="fleft mar_l7">
                    <input
                      className="form_col width37px inputext_brd"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[12092].value}
                      readOnly
                    />
                  </div>
                </div>
                <p className="margin0">
                  <strong>Deemed Acceptance</strong>
                </p>
                <p className="margin0">
                  Link Acceptance: Customer is excepted to provide acceptance of
                  the Link(s) in 5 days post delivery (subject to Hub location
                  delivery) after which VIL start billing the customer for th
                  Link(s). In absence of any shortcomings on the Link(s)
                  performance or acceptance communication from the customer with
                  in 5 days. VIL will deem the links as accepted by the customer
                  and inititate billing effective 5 days from link delivery.
                </p>
                <p className="declartion">
                  {' '}
                  Customer Declaration and Signatures follow{' '}
                </p>
                <div className="over_hid">
                  <div className="fleft width70 pad_t10">
                    <div className="">
                      <strong>Customer declearation</strong>
                    </div>
                    <div className="">
                      {' '}
                      I/We confirm having recived read and undoorstud the
                      Product &amp; Conditions (provided overleaf) and the
                      General Terms &amp; Conditions. I/We further confirm that
                      the tarif plan selected and applicable rates from part of
                      this aggrement (as difined herein) and I/We agree to abade
                      by the application law in force and also any statutory
                      amendments. or new legistations as may be enected from
                      time to tim, in so fare as they relate to the services.
                      I/We hereby declare and confirm that the informaton
                      provided by us is true and correct in all recepts and I/We
                      hereby undertake to b bound by the same.I/We confirm
                      having recived read and undoorstud the Product &amp;
                      Conditions (provided overleaf) and the General Terms &amp;
                      Conditions. I/We further confirm that the tarif plan
                      selected and applicable rates from part of this aggrement
                      (as difined herein) and I/We agree to abade by the
                      application law in force and also any statutory
                      amendments.{' '}
                    </div>
                  </div>
                  <div className="flright width30">
                    <div className="signateone">
                      {' '}
                      Official of Stamp <br />
                      Of Customer{' '}
                    </div>
                  </div>
                </div>
                <div className="over_hid">
                  <div className="fleft width30 pad_t10">
                    <div className="">
                      <input
                        className="form_col w_85 b_b1s"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12097].value}
                        readOnly
                      />
                    </div>
                    <div className="pad_5t">Signatures</div>
                  </div>
                  <div className="fleft width40 pad_t10">
                    <div className="">
                      <input
                        className="form_col w_85 b_b1s"
                        type="text"
                        name=""
                        value={iplcCafDataUpdated[12098].value}
                        readOnly
                      />
                    </div>
                    <div className="pad_5t">
                      Designation of Authorised Signatory
                    </div>
                  </div>
                  <div className="fleft width30 pad_t30">
                    {' '}
                    Place
                    <input
                      className="form_col b_b1s"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[12100].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="over_hid">
                  <div className="fleft width55 pad_t10">
                    {' '}
                    Name
                    <input
                      className="form_col b_b1s width92per"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[12102].value}
                      readOnly
                    />
                  </div>
                  <div className="flright pad_t10 mar_r20">
                    {' '}
                    Date
                    <input
                      className="form_col b_b1s"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[12101].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className=" pad_t10">
                  {' '}
                  Witness
                  <input
                    className="form_col b_b1s width90"
                    type="text"
                    name=""
                    value={iplcCafDataUpdated[12103].value}
                    readOnly
                  />
                </div>
                <div className="pad_t10">
                  {' '}
                  Name
                  <input
                    className="form_col b_b1s width90"
                    type="text"
                    name=""
                    value={iplcCafDataUpdated[12104].value}
                    readOnly
                  />
                </div>
                <div className="pad_t10">
                  {' '}
                  Address
                  <input
                    className="form_col b_b1s w_85"
                    type="text"
                    name=""
                    value={iplcCafDataUpdated[12105].value}
                    readOnly
                  />
                  <div className="pad_5t">
                    <input
                      className="form_col b_b1s widthfull"
                      type="text"
                      name=""
                      value=""
                    />
                  </div>
                  <div className="pad_5t">
                    <input
                      className="form_col b_b1s widthfull"
                      type="text"
                      name=""
                      value=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </page>
          <page size="A4">
            <div className="section2 font8">
              <div className="over_hid">
                <div className="fleft width70 pad_t10">
                  <div className="">
                    <div className="">
                      <input
                        className="form_col b_b1s"
                        type="text"
                        name=""
                        value=""
                        readOnly
                      />
                    </div>
                    <div className="pad_5t">
                      Signatures of volantries Signatures
                    </div>
                  </div>
                  <p className="margin0 pad_t10">Signatures</p>
                  <div className="pad_t10">
                    {' '}
                    Name
                    <input
                      className="form_col b_b1s wid50"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[12109].value}
                      readOnly
                    />
                  </div>
                  <div className=" pad_t10">
                    {' '}
                    Place
                    <input
                      className="form_col b_b1s wid50"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[12110].value}
                      readOnly
                    />
                  </div>
                  <div className=" pad_t10">
                    {' '}
                    Date
                    <input
                      className="form_col b_b1s wid50"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[12111].value}
                      readOnly
                    />
                  </div>
                  <div className=" pad_t10">
                    {' '}
                    Witness
                    <input
                      className="form_col b_b1s wid50"
                      type="text"
                      name=""
                      value={iplcCafDataUpdated[12112].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="flright width30">
                  <div className="signateone">
                    {' '}
                    Vodafone Idea <br />
                    Stamp{' '}
                  </div>
                </div>
              </div>
              <div className="pad_t15">
                <div className=" pad_t10">
                  {' '}
                  Witness
                  <input
                    className="form_col b_b1s width90"
                    type="text"
                    name=""
                    value={iplcCafDataUpdated[12113].value}
                    readOnly
                  />
                </div>
              </div>
              <div className="pad_t10">
                {' '}
                Name
                <input
                  className="form_col b_b1s width90"
                  type="text"
                  name=""
                  value={iplcCafDataUpdated[12114].value}
                  readOnly
                />
              </div>
              <div className="pad_t10">
                {' '}
                Address
                <input
                  className="form_col b_b1s w_85"
                  type="text"
                  name=""
                  value={iplcCafDataUpdated[12115].value}
                  readOnly
                />
                <div className="pad_5t">
                  <input
                    className="form_col b_b1s widthfull"
                    type="text"
                    name=""
                    value=""
                  />
                </div>
                <div className="pad_5t">
                  <input
                    className="form_col b_b1s widthfull"
                    type="text"
                    name=""
                    value=""
                  />
                </div>
              </div>
              <div className="signate float_img">
                {firstCaps !== '' ? (
                  <div style={{ padding: '10px' }} className={Signcss}>
                    {firstCaps + '  .  '}
                    <span className="under_line">{secondBold}</span>
                  </div>
                ) : (
                  ''
                )}
                {imageData !== null && imageData !== '' ? (
                  <div style={{ padding: '10px' }} className="signate_img">
                    <img
                      src={
                        imageData !== null
                          ? 'data:' + imageType + ';base64,' + encode(imageData)
                          : ''
                      }
                      alt={'Company Seal'}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className="pad_t15">
                <p>Vodafone Idea Ltd, office use only</p>
              </div>
              <p>
                Vodafone Idea Ltd. and (Vodafone Idea Logo) are Registered Trade
                Mark of VIL and are used under License. VIL of its subsidiaries/
                principal entities may not operate or provide any all the
                Services under this Agreement and the Services may be offered by
                VIL under license and managed services agreement with
                specialized venders and any warranties or representations of the
                third party vendor are transferred to the Customer on as is
                basis and no additional warranties or representation or service
                level agreements are provided here in. The Customer and VIL and
                End User and/or person named in the CAF as subscriber/ Customer
                is under contract with VIL Entity providing the Services.
              </p>
              <div className="connectcol">
                <p className="ready">
                  Connect your world to <strong>become a Ready Business</strong>
                </p>
                <div className="connecticons">
                  {' '}
                  <span>
                    <img src={WireImg} />
                    <p>Wire</p>
                  </span>{' '}
                  <span>
                    <img src={WirelessImg} />
                    <p>Wireless</p>
                  </span>{' '}
                  <span>
                    <img src={lotImg} />
                    <p>IoT</p>
                  </span>{' '}
                  <span>
                    <img src={CloudImg} />
                    <p>Cloud</p>
                  </span>
                </div>
                <p className="url">www.vodafone.in/business</p>
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </page>
          {excelJson !== null ? (
            <page size="A4">
              <div>
                <div className="pad_t10 section2">
                  <p className="customfom">Annexure to IPLC CAF :- </p>
                </div>
                <JsonToTable json={excelJson} />
                <div>
                  <p class="font10 " style={{ marginTop: '20px' }}>
                    <strong>Note:</strong> Address proofs will be required for
                    each of the sites listed above as part of standard
                    documentation guidelines; Master CAF referred here should be
                    signed by the same
                  </p>
                </div>

                <div class="fleft width50 pad_t10">
                  <p class="marb5">Account Manager Name</p>
                  <p class="margin0">
                    <input
                      class="form_col widthfull inputext"
                      type="text"
                      name="Account Manager Name"
                      readonly=""
                      value={iplcCafDataUpdated[12077].value}
                    />
                  </p>
                  <div className="signate" style={{ position: 'relative' }}>
                    <div className="signate_img input_sign">
                      {acctMngSignImageData !== null &&
                      acctMngSignImageData !== '' ? (
                        <img
                          src={
                            acctMngSignImageData !== null
                              ? 'data:' +
                                imageType +
                                ';base64,' +
                                encode(acctMngSignImageData)
                              : ''
                          }
                          alt={'Account Manager Sign'}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                    <div>Signature of the Account Manager </div>
                  </div>
                </div>
                <div
                  className="signate float_img"
                  style={{ position: 'initial' }}
                >
                  <div className="over_hid ">
                    <div className="over_hid ">
                      Authorised Signatory Name
                      {
                        <input
                          className="form_col inputext"
                          type="text"
                          name="Name_CD"
                          value={iplcCafDataUpdated[12072].value}
                          readOnly
                        />
                      }
                    </div>
                  </div>
                  {firstCaps !== '' ? (
                    <div style={{ padding: '10px' }} className={Signcss}>
                      {firstCaps + '  .  '}
                      <span className="under_line">{secondBold}</span>
                    </div>
                  ) : (
                    ''
                  )}
                  {imageData !== null && imageData !== '' ? (
                    <div style={{ padding: '10px' }} className="signate_img">
                      {
                        <img
                          src={
                            imageData != null
                              ? 'data:' +
                                imageType +
                                ';base64,' +
                                encode(imageData)
                              : ''
                          }
                          alt={'Company Seal'}
                        />
                      }
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </page>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  } else {
    IplcCafContentView = cafData ? (
      <div>
        <Center p={2} my={1}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor={chakraTheme.colors.secondary}
            color={chakraTheme.colors.brand[800]}
            size="md"
          />
        </Center>
      </div>
    ) : (
      <div style={{ marginTop: '200px', height: '300px', textAlign: 'center' }}>
        {' '}
        CAF Form not submitted yet.
      </div>
    );
  }
  return <div>{IplcCafContentView}</div>;
};

export default IplcCafForm;
